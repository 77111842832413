import React from "react";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import { useLocalization } from "gatsby-theme-i18n";
import Container from "../../../components/Container";
import NewLayout from "../../../components/NewLayout";
import InterestedItems from "../../../components/InterestedItems";
import { headers } from "../../../utils/navbar";
import ToggleButtonGroupCustomized from "../../../components/ToggleButtonGroupCustomized";
import AnnualDocTabs from "../../../components/AnnualDocTabs";
import financialInformationDocs from "../../../cms/financialInformationDocs/financialInformationDocs";
import esgDocs from "../../../cms/esgDocs/esgDocs";

const buttons = () => [
  {
    value: "financial",
    label: "Financial Reports",
  },
  {
    value: "annual",
    label: "Annual Reports",
  },
  {
    value: "esg",
    label: "ESG Reports",
  },
  {
    value: "presentations",
    label: "Presentations",
  },
];

function InvestorsFinancialReportsPage() {
  const { locale } = useLocalization();
  const [currentTab, setCurrentTab] = React.useState(buttons()[0].value);
  const data = financialInformationDocs[locale];
  const path = "attributes.publish_year";
  const interestedItems = [
    { ..._.get(headers(), "investors.elements.investors_about") },
    { ..._.get(headers(), "investors.elements.share_information") },
    { ..._.get(headers(), "investors.elements.financial_information_reports.elements.financial_calendar") },
  ];
  const content = {
    financial: _.groupBy(data.filter(({ attributes: { category, document_subcategory } }) => category === "Financial_Reports" && document_subcategory.data), path),
    annual: _.groupBy(data.filter(({ attributes: { category } }) => category === "Annual_Reports"), path),
    esg: _.groupBy(esgDocs[locale].filter(({ attributes: { category } }) => category === "ESG_Annual_Report"), path),
    presentations: _.groupBy(data.filter(({ attributes: { category } }) => category === "Presentations"), path),
  };
  const currentYears = Object.keys(content[currentTab]).reverse();

  return (
    <NewLayout
      byDefault
      apps="investors.financial_information_reports.financial_reports"
    >
      <Container pb={8}>
        <Typography mt={3} variant="h2">
          Allfunds Financial Information And Reports
        </Typography>
        <Box mt={2}>
          <AnnualDocTabs content={content[currentTab]} years={currentYears}>
            <Box mt={5} mb={{ xs: 1, sm: 3 }}>
              <ToggleButtonGroupCustomized
                items={buttons()}
                tab={currentTab}
                onChangeTab={(value) => setCurrentTab(value)}
              />
            </Box>
          </AnnualDocTabs>
        </Box>
        <InterestedItems items={interestedItems} />
      </Container>
    </NewLayout>
  );
}

export default InvestorsFinancialReportsPage;
